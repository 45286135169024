import { useState, useEffect } from 'react';
import { useImmer } from 'use-immer';
import Modal from 'react-modal';
import { Header } from '../../components';
import { InfiniteScroll, DetailRow, PdfExport, ExcelExport, Group, Freeze, GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, Edit, Sort, Search, Filter, Toolbar, ToolbarItems, CommandColumn, columnSelectionComplete } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { MdVerified } from 'react-icons/md';
import { AiOutlineSave, AiOutlineFileImage } from 'react-icons/ai';
import { CgSpinner } from 'react-icons/cg';
import { VscFilePdf } from 'react-icons/vsc';
import { L10n } from '@syncfusion/ej2-base';
import { useStateContext } from '../../contexts/ContextProviderAdminPMB';
import { dropdownData } from '../../data/initData';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const SUBMIT_URL = '/api/pmb/payment-register/';

const HasilTes = () => {
  const token = JSON.parse(localStorage.getItem('TOKEN'));
  const {bulkPublishHasilTest, setBulkPublishHasilTest, downloadExcelHasilTest, successMsg, uploadHasilTest, publishHasilTest, allHasilTest, getAllHasilTest, isLoading, setIsLoading, errMsg, setErrMsg, setSuccessMsg } = useStateContext();
  const [isOpenModalMurid, setIsOpenModalMurid] = useState(false);
  const [isOpenModalStatus, setIsOpenModalStatus] = useState(false);
  const [murid, setMurid] = useState({});
  const [selectedMurid, setSelectedMurid] = useState({});
  const [hasilTes, setHasilTes] = useImmer({
    id: null,
    nama_lengkap_anak: "",
    id_tahun_ajaran: null,
    pendaftaran_id: null,
    kategori: "",
    hasil_akhir: "",
    status: "",
    pdf: null,
    publish: false
  });
  const [previewPdfBukti, setPreviewPdfBukti] = useState("")
  

  const customStyles = {
    content: {
      width: '65%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      cursor: 'auto',
      padding: '48px'
    },
    overlay: {
      backgroundColor: 'rgba(0,0,0,.5)',
      cursor: 'pointer',
      zIndex: '999'
    }
  };

  const customStylesMurid = {
    content: {
      width: '95%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      cursor: 'auto',
      padding: '40px'
    },
    overlay: {
      backgroundColor: 'rgba(0,0,0,.5)',
      cursor: 'pointer',
      zIndex: '999'
    }
  };

  useEffect(() => {
    getAllHasilTest();
    console.log("allHasilTest === ", allHasilTest);
  },[]);

  useEffect(() => {
    console.log("bulkPublishHasilTest === ", bulkPublishHasilTest);
  },[bulkPublishHasilTest]);
  
  let grid;
  let gridMurid;
  // const renderingMode = 'Vertical';
  const editSettings = { allowEditing: false, allowDeleting: false };
  const toolbarOptions = ['Search',
    { text: 'Publish', prefixIcon: 'e-send', id: 'publish' },
    { text: 'Unduh Excel', prefixIcon: 'e-export-excel', id: 'excel' }
  ];
  const commands = [
    {
      buttonOption: {iconCss: ' e-icons e-changes-track', cssClass: 'e-outline', id: 'hasil-tes'},
      title: "Lihat Hasil Tes"
    },
  ];
  const commandClick = (args) =>  {
    const murid = args.rowData;
    const hasil = args.rowData.hasil_test;

    if (args.commandColumn.buttonOption.id == "murid") {
      console.log("args.rowData === ", args.rowData )
      setMurid(murid);
      setIsOpenModalMurid(true);
    }
    if (args.commandColumn.buttonOption.id == "hasil-tes") {
      console.log("args.rowData === ", args.rowData )
      setHasilTes(hasil)
      setIsOpenModalStatus(true);
      setPreviewPdfBukti("")
      console.log("args.rowData hasil === ", hasilTes )
    }
  }
  const onToolbarClick = (args) => {
    // console.log("onToolbarClick === ", args)
    if(args.item.id == "publish"){
      console.log("onToolbarClick bulkPublishHasilTest === ", bulkPublishHasilTest)
      publishHasilTest(bulkPublishHasilTest);
      // setBulkPublishHasilTest(draft => {
      //   draft["ids"] = []
      // })
    }
    else if (grid && args.item.id == 'excel') {
      // grid.excelExport();
      downloadExcelHasilTest()
    }
    else if(grid && args.item.id == "hasilTest_pdfexport"){
      grid.pdfExport();
    }
    else if(grid && args.item.id == "clear-filter"){
      grid.clearFiltering();
    }
  }

  // ON ROW SELECTED
  const onRowSelected = (args) => {
    console.log('onRowSelected === ', args.data);
    console.log('onRowSelected bulkPublishHasilTest  === ', bulkPublishHasilTest);
    setSelectedMurid(args.data);
    setBulkPublishHasilTest(draft => {
      draft["ids"].push(args.data.hasil_test.id)
    });
  } 

  function closeModalMurid() {
    setIsOpenModalMurid(false);
  }

  function closeModalStatus() {
    setIsOpenModalStatus(false);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  const childGridOptions = {
    columns: [
        { field: 'id', headerText: 'ID' },
        { field: 'status', headerText: 'Status'},
        { field: 'publish', headerText: 'Publish' },
    ],
    dataSource: murid.hasil_test,
    queryString: 'id'
  };

  Modal.setAppElement('#root');

  // Uploader component
  let uploadObj;
  let asyncSettings;
  let dropContainerRef;
  let dropContainerEle;
  dropContainerEle = null;
  dropContainerRef = element => {
      dropContainerEle = element;
  };
  asyncSettings = {
      saveUrl: BASE_URL+`/api/pmb/test-result`,
      removeUrl: 'https://ej2.syncfusion.com/services/api/uploadbox/Remove'
  };

  let minFileSize = 1000;
  let maxFileSize = 1000000;

  const onCreated = () => {
  }
  const onSelected = (args) => {
    console.log("onSelected arg === ", args)  
    const rawFile = args.filesData[0].rawFile;   
    console.log("RAW FILE === ", rawFile) 
    const previewPdfUrl = URL.createObjectURL(args.filesData[0].rawFile);
    setPreviewPdfBukti(previewPdfUrl)
    console.log("FILE URL === ", previewPdfUrl)
    setHasilTes((draft) => {
      draft.pdf = rawFile;
    });
  }

  function onRemoveFile(args) {
      args.postRawFile = false;
  }
  function onFileUpload(args) {
    console.log("UPLOADING..")
    console.log("onFileUpload === ", args)

    args.customFormData = [
      {pendaftaran_id: hasilTes.pendaftaran_id},
      {status: hasilTes.status},
      // {kategori: hasilTes.kategori},
      // {hasil_akhir: hasilTes.hasil_akhir},
      // {pdf: hasilTes.pdf},
    ];
    (args.currentRequest).setRequestHeader('Authorization', `Bearer ${token}`)

  }
  function onSuccess(args) {
    // getDocumentsData();
    console.log("SUCCESS")
    getAllHasilTest();
    console.log("allHasilTest === ", allHasilTest);
  }

  const uploadAll = () => {
    // uploadObj.upload();
    uploadHasilTest(hasilTes)
  }

  
  useEffect(() => {
    L10n.load({
      "id-BAHASA": {
        "uploader": {
          "Browse": "Cari Berkas",
          "Clear": "Bersihkan",
          "Upload": "Unggah",
          "cancel": "Batal",
          "delete": "Hapus Berkas",
          "dropFilesHint": "/ taruh Berkas disini",
          "inProgress": "Mengunduh",
          "invalidFileType": "Tipe berkas tidak diperbolehkan",
          "invalidMaxFileSize": `Ukuran berkas melebihi ${maxFileSize*0.000001} MB`,
          "invalidMinFileSize": `Ukuran file terlalu kecil! Harap unggah file dengan ukuran minimal ${maxFileSize*0.000001} KB`,
          "readyToUploadMessage": "Siap mengunggah",
          "remove": "Hapus",
          "removedFailedMessage": "Berkas tidak dapat dihapus",
          "removedSuccessMessage": "Berkas berhasil dihapus",
          "uploadFailedMessage": "Gagal mengunggah berkas",
          "uploadSuccessMessage": "Berkas berhasil diunggah",
        }
      }
    });
  },[]); 

  const updateDropDownCal = e => {
    const fieldName = e.element.ej2_instances[0].htmlattributes.name
    console.log("fieldName ===> ", fieldName)
    setHasilTes(draft => {
      draft[fieldName] = e.element.value
    })
  }

  // TOAST MESSAGE
  useEffect(() => {
    if(successMsg){
      successShow();
    }
  }, [successMsg])

  useEffect(() => {
    if(errMsg){
      dangerShow();
    }
  }, [errMsg])

  let toastObj;

  function toastClick() {
      console.log('Toast click event triggered');
  }
  function toastClose() {
      toastObj.hide('All');
  }
  
  function successShow() {
    toastObj = ToastUtility.show({
      title: 'Sukses',
      content: successMsg,
      cssClass: 'e-toast-success',
      timeOut: 3000,
      position: { X: 'Center', Y: 'Top' },
      showCloseButton: true,
      click: toastClick.bind(this),
      showProgressBar: true 
    });
    // CLEAR MESSAGE
    setTimeout(function () {
      setSuccessMsg("");
    }.bind(this), 7000);
  }
  function dangerShow() {
    toastObj = ToastUtility.show({
      title: 'Terjadi Kesalahan',
      content: errMsg,
      cssClass: 'e-toast-danger',
      timeOut: 7000,
      position: { X: 'Center', Y: 'Top' },
      showCloseButton: true,
      click: toastClick.bind(this),
      showProgressBar: true 
    });
    // CLEAR MESSAGE
    setTimeout(function () {
      setErrMsg("");
    }.bind(this), 7000);
  }

  const customAttributes = { class: 'customcss' };

  // const gridColTemplate = (props) => {
  //   return <span className="e-icons coldeleteicon" />;
  // }

  const headerCellInfo = (args) => {
    args.node.classList.add('sort-icon');
  }

  const valueAccessStatus = (field, data, column) => {
    console.log("valueAccessStatus === ", data.hasil_test.status)
    const value = data.hasil_test.status;
    if(value == "LULUS"){
      return `<span class='text-green-600 font-semibold'>LULUS</span>`
    }else{
      return `<span class='text-merah font-semibold'>TIDAK LULUS</span>`
    }
  };

  const valueAccessPublish = (field, data, column) => {
    console.log("valueAccessPublish === ", data.hasil_test.publish)
    const value = data.hasil_test.publish;
    if(value){
      return '<span class="e-btn-icon e-check e-icons e-large text-green-600"></span>'
    }
  };

  const queryCellInfo = (args) => {
    if (args.column.field == 'hasil_test.status') {
      if (args.data.hasil_test.status == "LULUS") { //based on condition we have set the font color to the cell 
        // args.cell.style.color = 'red';
        args.cell.classList.add('lulus');
      }
      else {
        // args.cell.style.color = 'blue';
        args.cell.classList.add('tidak-lulus');
      }
    }

    if (args.column.field == 'hasil_test.publish') {
      if (args.data.hasil_test.publish === true) { //based on condition we have set the font color to the cell 
        // args.cell.style.color = 'red';
        args.cell.classList.add('published');
      }
      else {
        // args.cell.style.color = 'blue';
        args.cell.classList.add('unpublish');
      }
    }
  }
  
  return(
    <>
      <Header category="Admin PMB" title="Hasil Tes" />

      <article className='relative'>

        <div className='absolute left-1 z-10 top-12 bg-[#f9fafb] w-6 h-7'></div>

        <GridComponent 
          id="hasilTest" 
          ref={g => grid = g}
          dataSource={allHasilTest} 
          toolbar={toolbarOptions} 
          editSettings={editSettings} 
          enableStickyHeader={true}
          rowHeight="40" 
          commandClick={commandClick} 
          rowSelected={onRowSelected} 
          // enableAdaptiveUI={true} 
          // rowRenderingMode={'Horizontal'} 
          allowSorting={true}
          allowFiltering={false}
          allowExcelExport={true}
          allowPdfExport={true}
          // allowGrouping={true}
          allowMultiSorting={true}
          // allowPaging={true}
          allowTextWrap={true}
          textWrapSettings={{ wrapMode: 'Header' }}
          // clipMode='Clip'
          toolbarClick={onToolbarClick} 
          pageSettings={{ pageSize: 10 }}
          gridLines={'Both'}
          selectionSettings={{type: "Multiple", checkboxOnly: true, persistSelection: true }}
          // enableVirtualization={true}
          enableInfiniteScrolling={true} 
          headerCellInfo={headerCellInfo}
          queryCellInfo={queryCellInfo}
        >
          <ColumnsDirective>
            <ColumnDirective freeze='Left' type='checkbox' width='30'/>
            <ColumnDirective field='id' headerText='ID' width='40' isPrimaryKey={true} visible={false} />
            <ColumnDirective field='nama_lengkap_anak' headerText='Nama Lengkap Anak' width='190' />
            <ColumnDirective field='ayah.nama_lengkap' headerText='Nama Orang Tua' width='190'/>
            <ColumnDirective field='ayah.nomor_ponsel' headerText='No. Ponsel' width='110' />
            <ColumnDirective field='ayah.alamat' headerText='Alamat' width='300' />
            <ColumnDirective field='tahapan_user.register' headerText='Pendaftaran Akun' width='110' />
            <ColumnDirective field='tahapan_user.payment_register' headerText='Pembayaran Registrasi' width='110' />
            <ColumnDirective field='tahapan_user.fill_form' headerText='Pengisian Formulir' width='110' />
            <ColumnDirective field='tahapan_user.test' headerText='Hasil Tes' width='110' />
            <ColumnDirective field='tahapan_user.daftar_ulang' headerText='Daftar Ulang' width='120' />
            <ColumnDirective field='tahapan_user.payment_education' headerText='Pembayaran Biaya Pendidikan' width='140' />

            {/* <ColumnDirective freeze='Right' allowEditing={false} field='hasil_test.status' headerText='Status' width='70' textAlign="Center" customAttributes={customAttributes} template={gridColTemplate} /> */}
            <ColumnDirective freeze='Right' field='hasil_test.status' headerText='Status' width='95' textAlign="Center" />
            <ColumnDirective freeze='Right' field='hasil_test.publish' headerText='Publish' width='90' textAlign="Center" />
            <ColumnDirective freeze='Right' headerText='Atur' width='60' textAlign="Center" commands={commands} customAttributes={customAttributes} />
          </ColumnsDirective>
          <Inject services={[InfiniteScroll, Edit, Search, Sort, Toolbar, Filter, CommandColumn, ExcelExport, PdfExport, Freeze]}/>

        </GridComponent>

        {/* MODAL MURID DETAIL */}
        <Modal
          isOpen={isOpenModalMurid}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModalMurid}
          style={customStylesMurid}
          contentLabel="Modal Murid"
        >
          <h2 className='mb-3 text-merah'>Data Murid</h2>
          {/* <p>DATA: {JSON.stringify(murid)}</p> */}

          <GridComponent 
            id="murid" 
            ref={g => gridMurid = g}
            dataSource={[murid]} 
            editSettings={editSettings} 
            rowHeight="40" 
            enableAdaptiveUI={true} 
            // rowRenderingMode={'Vertical'}
            gridLines={'Both'}
            // childGrid={childGridOptions}
          >
            <ColumnsDirective>
              <ColumnDirective field='id' headerText='ID' width='30' isPrimaryKey={true} visible={false} />
              <ColumnDirective field='nama_lengkap_anak' headerText='Name Lengkap' width='180'/>
              <ColumnDirective field='nomor_akta_lahir_anak' headerText='No. Akta Lahir' width='120'/>
              <ColumnDirective field='nama_ayah' headerText='Nama Ayah' width='120'/>
              <ColumnDirective field='alamat_ayah' headerText='Alamat Ayah' width='120'/>
              <ColumnDirective field='nama_ibu' headerText='Nama Ibu' width='120'/>
              <ColumnDirective field='alamat_ibu' headerText='Alamat Ibu' width='120'/>
              <ColumnDirective field='nama_wali' headerText='Nama Wali' width='120'/>
              <ColumnDirective field='alamat_wali' headerText='Alamat Wali' width='120'/>
            </ColumnsDirective>
            <Inject services={[CommandColumn]}/>
          </GridComponent>

        </Modal>

        <Modal
          isOpen={isOpenModalStatus}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModalStatus}
          style={customStyles}
          contentLabel="Modal Status"
        >
          <h2 className='mb-3 text-merah'>{selectedMurid.nama_lengkap_anak}</h2>

          <form className='grid grid-cols-1 mt-3 gap-7' >

            <section>
          
              <div className={`flex flex-wrap`}>
                <label htmlFor="surat_hasil_test" className="mt-4 mb-1 form-label">
                  Status <span className="ml-1 text-merah">*</span>
                </label>
                <DropDownListComponent 
                  id="surat_hasil_test"
                  placeholder=""
                  name="status" 
                  dataSource={dropdownData.status} 
                  fields={ { value: 'text', text: 'text' }} 
                  value={hasilTes.status} 
                  change={updateDropDownCal} 
                  popupHeight="auto" 
                  enabled={hasilTes.publish ? false : true}
                />
              </div>

              
              <label htmlFor="pdf" className="block mt-4 mb-1">
                Surat Hasil Tes {!hasilTes.pdf ? <span className="ml-1 text-merah">*</span> : <span><MdVerified className='inline-block text-md text-green-600 ml-0.5 mb-1' /> <strong className='text-green-600 text'>Sudah Diunggah</strong></span>}
              </label>
              <div className="w-full p-3 mb-3 overflow-hidden text-center rounded-lg bg-soft">
                {previewPdfBukti ?
                <embed src={previewPdfBukti} height="240" className="w-full m-auto" />
                : hasilTes.pdf ?
                <embed src={hasilTes.pdf} height="240" className="w-full m-auto" />
                :
                <div className='flex items-center justify-center h-240'><VscFilePdf className='inline-block text-md text-merah text-5xl ml-0.5 mb-1' /></div>
                }
              </div>
              {!hasilTes.publish &&
                <>
                  <UploaderComponent 
                    id='pdf' 
                    type='file' 
                    ref={(scope) => { uploadObj = scope; }} 
                    asyncSettings={asyncSettings} 
                    removing={onRemoveFile.bind(this)}
                    uploading={onFileUpload.bind(this)} 
                    success={onSuccess.bind(this)} 
                    locale='id-BAHASA'
                    allowedExtensions='.pdf'
                    minFileSize={minFileSize}
                    maxFileSize={maxFileSize} 
                    multiple={false}
                    autoUpload={false}
                    buttons={{ browse: !hasilTes.pdf ? "Unggah Berkas" : "Ganti Berkas" }}
                    selected={onSelected} 
                    created={onCreated}
                  />
                  <small className='text-gray-400 '><i>Jenis berkas: .pdf, max: 1 MB</i></small>
                </> 
              }

              {/* <div className='mt-3'>
                <CheckBoxComponent change={handleCheckbox.bind(this)} label="Publish" data-name="publish" value={hasilTes.is_published} checked={hasilTes.is_published} cssClass="e-success" />
              </div> */}

            </section>

          </form>
          
          {!hasilTes.publish &&
          <section className='flex mt-3'>
            <button className="w-auto btn-merah" onClick={uploadAll}>
              {isLoading ? <CgSpinner className="mr-2 text-xl animate-spin" /> : <AiOutlineSave className='mr-2 text-2xl' /> }
              Update Hasil Tes
            </button>
          </section>
          }

        </Modal>

      </article>
    </>
  )
};
export default HasilTes;

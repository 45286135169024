import { useState, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { Link } from 'react-router-dom';
import { AiOutlineSave, AiOutlineFileImage } from 'react-icons/ai';
import { CgSpinner } from 'react-icons/cg';
import { BsChevronRight,  BsChevronLeft } from 'react-icons/bs';
import { VscFilePdf } from 'react-icons/vsc';
import { MdVerified } from 'react-icons/md';
import { L10n } from '@syncfusion/ej2-base';
import { UploaderComponent, FilesDirective, UploadedFilesDirective } from '@syncfusion/ej2-react-inputs';
import axios from '../api/axios';

import { useStateContext } from '../contexts/ContextProvider';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const SUBMIT_URL = '/api/pmb/submit';

const FormBerkasPendaftaran = ({id, indexMurid}) => {
  const token = JSON.parse(localStorage.getItem('TOKEN'));
  const { documents, setDocuments, getDocumentsData, errMsg, setErrMsg, setSuccessMsg, formCheck, getFormCheck } = useStateContext();
  const [isLoading, setIsLoading] = useState(false);  

  const [berkas, setBerkas] = useImmer(
    {
      id: null,
      akte_kelahiran: "",
      kartu_keluarga: "",
      foto: "",
      rapor: "",
    }
  )


  // Uploader component
  let uploadAkteKelahiran;
  let uploadKartuKeluarga;
  let uploadRapor;
  let uploadFoto;
  let asyncSettings;
  let dropContainerRef;
  let dropContainerEle;
  dropContainerEle = null;
  dropContainerRef = element => {
      dropContainerEle = element;
  };
  asyncSettings = {
      saveUrl: BASE_URL+`/api/pmb/document`,
      removeUrl: 'https://ej2.syncfusion.com/services/api/uploadbox/Remove'
  };

  let minFileSize = 1000;
  let maxFileSize = 1000000;
  
  const onCreated = () => {
  }
  const onSelected = (args) => {
    const uploaderId = args.event.target.id;
    console.log("onSelected uploaderId === ",uploaderId) 

    const rawFile = args.filesData[0].rawFile;
    console.log("RAW FILE === ", rawFile)
    const objUrl = URL.createObjectURL(args.filesData[0].rawFile);
    console.log("OBJECT URL === ", objUrl)
    setBerkas(draft => {
      draft[uploaderId] = objUrl;
    })
  }
  function onRemoveFile(args) {
      args.postRawFile = false;
  }
  function onFileUpload(args) {
    console.log("UPLOADING..")
    console.log("onFileUpload === ", args)
    args.customFormData = [{'id': documents[indexMurid].id}];
    (args.currentRequest).setRequestHeader('Authorization', `Bearer ${token}`)
  }
  function onSuccess(args) {
    getDocumentsData();
    console.log("SUCCESS")
  }
  const onFailed = (args) => {
    const res = JSON.parse(args.e.target.response)
    console.log("onFailed === ", res.errors.tgl_bayar[0])
    Object.entries(res.errors).map(([, fieldErrors]) => 
      fieldErrors.map((fieldError, index) => 
        setErrMsg(fieldError)
      )
    )
  }



  useEffect(() => {
    L10n.load({
      "id-BAHASA": {
        "uploader": {
          "Browse": "Cari Berkas",
          "Clear": "Bersihkan",
          "Upload": "Unggah",
          "cancel": "Batal",
          "delete": "Hapus Berkas",
          "dropFilesHint": "/ taruh Berkas disini",
          "inProgress": "Mengunduh",
          "invalidFileType": "Tipe berkas tidak diperbolehkan",
          "invalidMaxFileSize": `Ukuran berkas melebihi ${maxFileSize*0.000001} MB`,
          "invalidMinFileSize": `Ukuran file terlalu kecil! Harap unggah file dengan ukuran minimal ${maxFileSize*0.000001} KB`,
          "readyToUploadMessage": "Siap mengunggah",
          "remove": "Hapus",
          "removedFailedMessage": "Berkas tidak dapat dihapus",
          "removedSuccessMessage": "Berkas berhasil dihapus",
          "uploadFailedMessage": "Gagal mengunggah berkas",
          "uploadSuccessMessage": "Berkas berhasil diunggah",
        }
      }
    });
  },[]); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(SUBMIT_URL, null,
        {
          headers: { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setIsLoading(false);
      setSuccessMsg("Data berhasil disimpan");
      setErrMsg("");
      console.log("RESPONSE ==== " + JSON.stringify(response?.data));
      getFormCheck();
      console.log("getFormCheck === ", formCheck);
    } catch (err) {
      const errors = err?.response?.data.errors
      console.error("ERROR === ", errors)
      setErrMsg(errors);
      setIsLoading(false);       
    }
  }

  function getExtension(filename) {
    return filename.split(".").pop();
  }

  let akte_kelahiran = documents[indexMurid].akte_kelahiran;
  let kartu_keluarga = documents[indexMurid].kartu_keluarga;
  let rapor          = documents[indexMurid].rapor;
  let foto           = documents[indexMurid].foto;

  let name_akte_kelahiran = akte_kelahiran.replace(/\.[^/.]+$/, "");
  let name_kartu_keluarga = kartu_keluarga.replace(/\.[^/.]+$/, "");
  let name_rapor          = rapor.replace(/\.[^/.]+$/, "");
  let name_foto           = foto.replace(/\.[^/.]+$/, "");

  let foto_extension = getExtension(documents[indexMurid].foto);

  return(
      <article>

        <div className='grid mt-3 xs:grid-cols-1 md:grid-cols-2 gap-7'>


          {/* COL 1 */}
          <section>
            <label htmlFor="akte_kelahiran" className="block mt-4 mb-1">
              Akte Kelahiran {!akte_kelahiran ? <span className="ml-1 text-merah">*</span> : <span><MdVerified className='inline-block text-md text-green-600 ml-0.5 mb-1' /> <strong className='text-green-600 text'>Sudah Diunggah</strong></span>}
            </label>
            <div className="flex items-center justify-center w-full p-3 mb-3 text-center rounded-lg h-[240px] bg-soft overflow-y-auto">
              {berkas.akte_kelahiran ?
                <embed src={berkas.akte_kelahiran} height="240" className="w-full m-auto" />
                : documents[0].akte_kelahiran ?
                <embed src={documents[0].akte_kelahiran} height="240" className="w-full m-auto" />
                :
                <div className='flex items-center justify-center h-240'><VscFilePdf className='inline-block text-md text-merah text-5xl ml-0.5 mb-1' /></div>
              }
            </div>
            <UploaderComponent 
              id='akte_kelahiran' 
              type='file' 
              ref={(scope) => { uploadAkteKelahiran = scope; }} 
              asyncSettings={asyncSettings} 
              removing={onRemoveFile.bind(this)}
              uploading={onFileUpload.bind(this)} 
              success={onSuccess.bind(this)} 
              locale='id-BAHASA'
              allowedExtensions='.pdf'
              minFileSize={minFileSize}
              maxFileSize={maxFileSize} 
              multiple={false}
              autoUpload={false}
              buttons={{ browse: !akte_kelahiran ? "Unggah Berkas" : "Ganti Berkas" }}
              selected={onSelected} 
              created={onCreated}
              showFileList={true}
              failure={onFailed}
            >
              {/* <FilesDirective>
								<UploadedFilesDirective name={akte_kelahiran} size={25000} type=".pdf"></UploadedFilesDirective>
							</FilesDirective> */}
            </UploaderComponent>
            <small className='text-gray-400 '><i>Jenis berkas: .pdf, max: 1 MB</i></small>
          </section>

          {/* COL 2 */}
          <section>
            <label htmlFor="akte_kelahiran" className="block mt-4 mb-1">
              Kartu Keluarga {!kartu_keluarga ? <span className="ml-1 text-merah">*</span> : <span><MdVerified className='inline-block text-md text-green-600 ml-0.5 mb-1' /> <strong className='text-green-600 text'>Sudah Diunggah</strong></span>}
            </label>
            <div className="flex items-center justify-center w-full p-3 mb-3 text-center rounded-lg h-[240px] bg-soft overflow-y-auto">
              {berkas.kartu_keluarga ?
                <embed src={berkas.kartu_keluarga} height="240" className="w-full m-auto" />
                : documents[0].kartu_keluarga ?
                <embed src={documents[0].kartu_keluarga} height="240" className="w-full m-auto" />
                :
                <div className='flex items-center justify-center h-240'><VscFilePdf className='inline-block text-md text-merah text-5xl ml-0.5 mb-1' /></div>
              }
            </div>
            <UploaderComponent 
              id='kartu_keluarga' 
              type='file' 
              ref={(scope) => { uploadKartuKeluarga = scope; }} 
              asyncSettings={asyncSettings} 
              removing={onRemoveFile.bind(this)}
              uploading={onFileUpload.bind(this)} 
              success={onSuccess.bind(this)} 
              locale='id-BAHASA'
              allowedExtensions='.pdf'
              minFileSize={minFileSize}
              maxFileSize={maxFileSize} 
              multiple={false}
              buttons={{ browse: !kartu_keluarga ? "Unggah Berkas" : "Ganti Berkas" }}
              selected={onSelected} 
              created={onCreated}
              showFileList={true}
              failure={onFailed}
              autoUpload={false}

            >
              {/* <FilesDirective>
								<UploadedFilesDirective name={kartu_keluarga} size={25000} type=".pdf"></UploadedFilesDirective>
							</FilesDirective> */}
            </UploaderComponent>
            <small className='text-gray-400 '><i>Jenis berkas: .pdf, max: 1 MB</i></small>
          </section>

          {/* COL 3 */}
          <section>
            <label htmlFor="akte_kelahiran" className="block mt-4 mb-1">
              Rapor {!rapor ? <span className="ml-1 text-merah">*</span> : <span><MdVerified className='inline-block text-md text-green-600 ml-0.5 mb-1' /> <strong className='text-green-600 text'>Sudah Diunggah</strong></span>}
            </label>
            <div className="flex items-center justify-center w-full p-3 mb-3 text-center rounded-lg h-[240px] bg-soft overflow-y-auto">
              {berkas.rapor ?
                <embed src={berkas.rapor} height="240" className="w-full m-auto" />
                : documents[0].rapor ?
                <embed src={documents[0].rapor} height="240" className="w-full m-auto" />
                :
                <div className='flex items-center justify-center h-240'><VscFilePdf className='inline-block text-md text-merah text-5xl ml-0.5 mb-1' /></div>
              }
            </div>
            <UploaderComponent 
              id='rapor' 
              type='file' 
              ref={(scope) => { uploadRapor = scope; }} 
              asyncSettings={asyncSettings} 
              removing={onRemoveFile.bind(this)}
              uploading={onFileUpload.bind(this)} 
              success={onSuccess.bind(this)} 
              locale='id-BAHASA'
              allowedExtensions='.pdf'
              minFileSize={minFileSize}
              maxFileSize={maxFileSize} 
              multiple={false}
              buttons={{ browse: !akte_kelahiran ? "Unggah Berkas" : "Ganti Berkas" }}
              selected={onSelected} 
              created={onCreated}
              showFileList={true}
              failure={onFailed}
              autoUpload={false}
            >
              {/* <FilesDirective>
								<UploadedFilesDirective name={rapor} size={25000} type=".pdf"></UploadedFilesDirective>
							</FilesDirective> */}
            </UploaderComponent>
            <small className='text-gray-400 '><i>Jenis berkas: .pdf, max: 1 MB</i></small>
          </section>

          {/* COL 4 */}
          <section>
            <label htmlFor="akte_kelahiran" className="block mt-4 mb-1">
              Pas Foto 3x4 {!foto ? <span className="ml-1 text-merah">*</span> : <span><MdVerified className='inline-block text-md text-green-600 ml-0.5 mb-1' /> <strong className='text-green-600 text'>Sudah Diunggah</strong></span>}
            </label>
            <div className="flex items-center justify-center w-full p-3 mb-3 text-center rounded-lg h-[240px] bg-soft overflow-y-auto">
              {berkas.foto ?
              <img src={berkas.foto} className="w-full m-auto" />
              : documents[0].foto ?
              <img src={documents[0].foto} className="w-full m-auto" />
              :
              <span><AiOutlineFileImage className='inline-block text-md text-merah text-5xl ml-0.5 mb-1' /></span>
              }
            </div>
            <UploaderComponent 
              id='foto' 
              type='file' 
              ref={(scope) => { uploadFoto = scope; }} 
              asyncSettings={asyncSettings} 
              removing={onRemoveFile.bind(this)}
              uploading={onFileUpload.bind(this)} 
              success={onSuccess.bind(this)} 
              locale='id-BAHASA'
              allowedExtensions='.jpg,.png,.jpeg'
              minFileSize={minFileSize}
              maxFileSize={maxFileSize} 
              multiple={false}
              buttons={{ browse: !foto ? "Unggah Berkas" : "Ganti Berkas" }}
              selected={onSelected} 
              created={onCreated}
              showFileList={true}
              failure={onFailed}
              autoUpload={false}
            >
              {/* <FilesDirective>
								<UploadedFilesDirective name={name_foto} size={25000} type={foto_extension}></UploadedFilesDirective>
							</FilesDirective> */}
            </UploaderComponent>
            <small className='text-gray-400 '><i>Jenis berkas: .jpg, .png, max: 1 MB</i></small>
          </section>

        </div>

        <section className='flex mt-12'>
          <button type="button" className="w-auto btn-merah" onClick={handleSubmit}>
            {isLoading ? <CgSpinner className="mr-2 text-xl animate-spin" /> : <AiOutlineSave className='mr-2 text-2xl' /> }
            Simpan
          </button>

          <div className='flex justify-end w-full'>
            <Link to={"/pmb/form-pernyataan"} className="w-auto pl-0 mx-0 bg-transparent shadow-none btn-merah hover:bg-transparent text-merah hover:text-gelap">
              <BsChevronLeft className='text-xl m-0 mr-2 mt-0.5' /> Kembali
            </Link>

            {/* <Link to={"/berkas-pendaftaran"} className="w-auto pr-0 mx-0 bg-transparent shadow-none btn-merah hover:bg-transparent text-merah hover:text-gelap">
              Selanjutnya <BsChevronRight className='text-xl ml-2 mt-0.5' />
            </Link> */}
          </div>
        </section>

      </article>
  )
};
export default FormBerkasPendaftaran;

import { useState, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, Edit, Toolbar, Search, CommandColumn } from '@syncfusion/ej2-react-grids';
import { ToastComponent, ToastUtility } from '@syncfusion/ej2-react-notifications';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

import { DialogComponent, } from '@syncfusion/ej2-react-popups';
import { FaCheckCircle, FaTimesCircle, FaInfoCircle, FaRegCheckCircle } from 'react-icons/fa';
import GelombangPMB from '../../components/admin-pmb/GelombangPMB';
import { AiOutlinePlus } from 'react-icons/ai';
import { CgSpinner } from 'react-icons/cg';
import { Header } from '../../components';
import { useStateContext } from '../../contexts/ContextProviderAdminPMB';

const SetupPMB = () => {
  const { archiveTahunAjaran, publishTahunAjaran, gelombang, getGelombangById, postGelombang, createInitialGelombang, updateGelombang, selectedTahunAjaran, setSelectedTahunAjaran, hapusTahunAjaran, tambahTahunAjaran, indexGelombang, setIndexGelombang, gridFeesData, gridDocsData, postTahunAjaran, currentTahunAjaran, setCurrentTahunAjaran, tahunAjaran, setTahunAjaran, tahunAjaranById, getTahunAjaranByPublish, allTahunAjaran, getAllTahunAjaran, isLoading, setIsLoading, errMsg, setErrMsg, successMsg, setSuccessMsg } = useStateContext();
  const [status, setStatus] = useImmer({
    hideDialog  : false,
    toolbarClick: "",
    hapus: false
  });
  
  useEffect(() => {
    getAllTahunAjaran();
    getTahunAjaranByPublish(1);
  }, []);

  const tabContent = () => {
    return (
      <GelombangPMB key={indexGelombang.toString()} indexGelombang={indexGelombang} />
    )
  }

  const tabSelected = (e) => {
    setIndexGelombang(e.selectedIndex)
    setErrMsg('');
  }

  const tambahGelombang = (e) => {
    e.preventDefault();
    createInitialGelombang(selectedTahunAjaran.id);
    console.log("tahunAjaranById.groups === ", tahunAjaranById)
  }

  let grid;

  const loadGridTahunAjaran = () => {
    let instance = document.getElementById('GridTahunAjaran').ej2_instances[0];
    if (instance) {
      instance.element.addEventListener('mouseup', function (e) {
        if (e.target.classList.contains("e-rowcell")) {
          if (instance.isEdit)
              instance.endEdit();
          let index = parseInt(e.target.getAttribute("Index"));
          instance.selectRow(index);
          instance.startEdit();
      }
        ;
      });
    }
  };
  const toolbarOptions = [
    { text: '', prefixIcon: 'e-add', id: 'tambah', tooltipText: 'Tambah' },
    { text: '', prefixIcon: 'e-edit', id: 'ubah', tooltipText: 'Ubah' },
    { text: '', prefixIcon: 'e-cancel', id: 'batal', tooltipText: 'Batal' },
    { text: '', prefixIcon: 'e-update', id: 'simpan', tooltipText: 'Simpan' },
    { text: '', prefixIcon: 'e-folder', id: 'arsip', align: 'Right', tooltipText: 'Arsip', },
    { text: '', prefixIcon: 'e-send', id: 'publish', align: 'Right', tooltipText: 'Publish', },
    { text: '', prefixIcon: 'e-delete', id: 'hapus', align: 'Right', tooltipText: 'Hapus', disabled: status.hapus },
    { text: 'Search' }
  ];
  const editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, showConfirmDialog: true, showDeleteConfirmDialog: true, newRowPosition: 'Top'};
  const commands = [
    // { 
    //   buttonOption: { iconCss: ' e-icons e-delete', cssClass: 'e-flat', id: 'hapus' }, 
    //   title: "Hapus Tahun Ajaran"
    // },
    // { 
    //   buttonOption: { iconCss: ' e-icons e-folder', cssClass: 'e-flat', id: 'publish' }, 
    //   title: "Publish Tahun Ajaran"
    // },
    // { 
    //   buttonOption: { iconCss: ' e-icons e-hyperlink-open', cssClass: 'e-flat', id: 'publish' }, 
    //   title: "Publish Tahun Ajaran"
    // },
    { 
      buttonOption: { iconCss: ' e-icons e-properties-1', cssClass: 'e-outline', id: 'setup' }, 
      title: "Setup Tahun Ajaran"
    }
  ];
  const onToolbarClick = (args) => {
    const currentViewData =grid.currentViewData

    if(args.item.id == "tambah"){
      grid.addRecord();
    }
    else if(args.item.id == "ubah"){
      grid.startEdit();
    }
    else if(args.item.id == "batal"){
      grid.closeEdit();
    }
    else if(args.item.id == "simpan"){
      grid.endEdit();
    }
    else if(args.item.id == "arsip"){
      setStatus({ hideDialog: true, toolbarClick: "Arsip" });
      // archiveTahunAjaran(selectedTahunAjaran.id)
    }
    else if(args.item.id == "publish"){
      setStatus({ hideDialog: true, toolbarClick: "Publish" });
      // publishTahunAjaran(selectedTahunAjaran.id)
    }
    else if(args.item.id == "hapus"){
      console.log("hapus === ", args)
      // grid.deleteRecord(grid.getSelectedRows()[0]);
      console.log("selectedTahunAjaran === ", selectedTahunAjaran)
      if(!selectedTahunAjaran.publish){
        setStatus({ hideDialog: true, toolbarClick: "Hapus" });
      }else{
        setStatus({ hideDialog: true, toolbarClick: "Tidak dapat dihapus" });
      }
    }
  }
  const onActionBegin = (args) => {
    // console.log('onActionBegin === ', args.rowData);
  }
  const onActionComplete= (args) => {
    // console.log('onActionComplete === ', args.rowData);
  }

  // ROW SELECTED
  const onRowSelected = (args) => {
    console.log('onRowSelected === ', args.data);
    setSelectedTahunAjaran(args.data)
    setIndexGelombang(0);
    getGelombangById(args.data.id);
    console.log('onRowSelected: selectedTahunAjaran === ', selectedTahunAjaran.tahun_ajaran);
    if( args.data.id == 0){
      tambahTahunAjaran(args.data.tahun_ajaran);
      // setIndexGelombang(0);
      // getGelombangById(args.data.id);
    }
  } 
  const onColumnSelected = (args) => {
    // console.log('onColumnSelected', args.data);
  } 

  // COMMAND CLICK
  const commandClick = (args) =>  {
    console.log('commandClick === ', args.rowData);

    setSelectedTahunAjaran(args.rowData)
    console.log('commandClick: selectedTahunAjaran === ', selectedTahunAjaran);

    if (args.commandColumn.buttonOption.id == "setup") {
      setIndexGelombang(0);
      getGelombangById(args.rowData.id);
    }
    // else if(args.commandColumn.buttonOption.id == "publish") {
    //   publishTahunAjaran(args.rowData.id);
    // }
  }

  let buttons;
  let toolbarDialog;

  const animationSettings = { effect: 'None' };
  buttons = [
      {
          click: dlgButtonClick,
          buttonModel: {
              content: status.toolbarClick,
              isPrimary: true,
          },
      },
  ];
  function dlgButtonClick() {
    setStatus({ hideDialog: false, toolbarClick: "" });
    if(!selectedTahunAjaran.publish){
      switch (status.toolbarClick) {
        case "Hapus":
            return hapusTahunAjaran(selectedTahunAjaran.id);
        case "Arsip":
            return archiveTahunAjaran(selectedTahunAjaran.id);
        case "Publish":
            return publishTahunAjaran(selectedTahunAjaran.id);
        default:
            return setStatus(draft => {draft["hideDialog"] = false;})
      }
    }
  }
  function dialogClose() {
    setStatus(draft => {
      draft["hideDialog"] = false;           
    })
  }
  function dialogOpen(toolbarClick) {
    setStatus(draft => {
      draft["hideDialog"] = true;           
    })
  }
  // const toolbarDialog.overlayClick = () => {
  //   setStatus({ hideDialog: false });
  // };


  // TOAST MESSAGE
  useEffect(() => {
    if(successMsg){
      successShow();
    }
  }, [successMsg])

  useEffect(() => {
    if(errMsg){
      dangerShow();
    }
  }, [errMsg])

  let toastObj;

  function toastShow() {
    toastObj = ToastUtility.show({
      title: 'Toast Title',
      content: 'Toast shown using utility function with ToastModel',
      timeOut: 20000,
      position: { X: 'Right', Y: 'Bottom' },
      showCloseButton: true,
      click: toastClick.bind(this),
      buttons: [{
        model: { content: 'Close' }, click: toastClose.bind(this)
      }]
    });
  }
  function toastClick() {
      console.log('Toast click event triggered');
  }
  function toastClose() {
      toastObj.hide('All');
  }
  
  function successShow() {
      // toastObj = ToastUtility.show(successMsg, 'Success', 7000);
      toastObj = ToastUtility.show({
        title: 'Sukses',
        content: successMsg,
        cssClass: 'e-toast-success',
        timeOut: 3000,
        position: { X: 'Center', Y: 'Top' },
        showCloseButton: true,
        click: toastClick.bind(this),
        showProgressBar: true 
        // buttons: [{
        //   model: { content: 'Tutup' }, click: toastClose.bind(this)
        // }]
      });
      // CLEAR MESSAGE
      setTimeout(function () {
        setSuccessMsg("");
      }.bind(this), 7000);
  }
  function dangerShow() {
      // toastObj = ToastUtility.show(errMsg, 'Error', 7000);
      toastObj = ToastUtility.show({
        title: 'Terjadi Kesalahan',
        content: errMsg,
        cssClass: 'e-toast-danger',
        timeOut: 7000,
        position: { X: 'Center', Y: 'Top' },
        showCloseButton: true,
        click: toastClick.bind(this),
        showProgressBar: true 
        // buttons: [{
        //   model: { content: 'Tutup' }, click: toastClose.bind(this)
        // }]
      });
      // CLEAR MESSAGE
      setTimeout(function () {
        setErrMsg("");
      }.bind(this), 7000);
  }
  // function infoShow() {
  //   toastObj = ToastUtility.show('Please read the comments carefully', 'Information', 20000);
  // }
  // function warningShow() {
  //     toastObj = ToastUtility.show('There was a problem with your network connection', 'Warning', 20000);
  // }
  function hideToast() {
      toastObj.hide('All');
  }

  return(
  <>
    <Header category="Admin PMB" title="Setup PMB" />

    <article>
      <form>
        <section className='rounded-lg mb-7 px-7 pb-7 bg-soft'>
          <div className='relative p-2 mb-3 -mt-4 rounded-tl-lg rounded-tr-lg px-7 text-putih bg-merah -mx-7'>
            <h4>Daftar Tahun Ajaran</h4>
          </div>

          <div className='mt-7'>
            <GridComponent 
              id="GridTahunAjaran"
              // load={loadGridTahunAjaran}
              dataSource={allTahunAjaran} 
              ref={(g) => (grid=g)}
              toolbar={toolbarOptions}
              editSettings={editSettings} 
              height={128} 
              commandClick={commandClick}
              toolbarClick={onToolbarClick} 
              rowSelected={onRowSelected} 
              actionBegin={onActionBegin}
              actionComplete={onActionComplete}
              // enableAdaptiveUI={true} 
            >
              <ColumnsDirective>
                <ColumnDirective defaultValue='0' field='id' headerText='ID' width={50} isPrimaryKey={true} visible={false} />
                <ColumnDirective field='tahun_ajaran' headerText='Tahun Ajaran' width={'auto'} />
                <ColumnDirective allowEditing={false} field='dibuat_oleh.nama_lengkap' headerText='Dibuat oleh' />
                <ColumnDirective allowEditing={false} field='archived_at' headerText='Tgl. Arsip' format='yMd' width={200} textAlign="Center" visible={true} />
                <ColumnDirective allowEditing={false} field='pengarsipan' headerText='Arsip' width={60} textAlign="Center" displayAsCheckBox={true} editType='booleanedit' />
                <ColumnDirective allowEditing={false} field='publish' headerText='Publish' width={60} textAlign="Center" displayAsCheckBox={true} editType='booleanedit' />
                <ColumnDirective headerText='Kelola' width={60} commands={commands} textAlign="Center"  />
              </ColumnsDirective>
              <Inject services={[Page, CommandColumn, Edit, Toolbar, Search]}/>
            </GridComponent>
          </div>
          
        </section>

        {/* AKTIFITAS */}
        <section className='relative rounded-lg p-7 bg-soft mb-7'>
          <h4 className='p-2 rounded-tl-lg rounded-tr-lg px-7 text-putih bg-merah -mx-7 -mt-7 mb-7'>Tahun Ajaran {selectedTahunAjaran.tahun_ajaran}</h4>

          {gelombang.length > 0 &&
            <>
              {!selectedTahunAjaran.publish &&
                <button onClick={tambahGelombang} 
                  className={`${gelombang.length >= 3 && 'hidden'} absolute top-0 right-0 w-auto px-3 py-1 m-1.5 btn-merah bg-green-700 hover:bg-green-600`}
                >
                  {isLoading ? <CgSpinner className="text-xl animate-spin" /> : <AiOutlinePlus className='text-2xl' /> }
                  <span className='md:ml-2 md:inline-block xs:hidden'>Tambah Gelombang</span>
                </button>
              }
            </>
          }

          { gelombang.length > 0 &&
            <TabComponent heightAdjustMode='None' selected={tabSelected} >
              <TabItemsDirective>
                {gelombang?.map(( { id }, index ) => (
                  <TabItemDirective 
                    key={index.toString()} 
                    header={{ text: "Gelombang "+(index+1) }}
                    content={tabContent} 
                  />
                ))}
              </TabItemsDirective>
            </TabComponent>
          }
          
          { gelombang.length <= 0 &&
            <div className="relative px-4 py-3 mt-3 text-sm text-yellow-900 bg-yellow-100 rounded-md" aria-live="assertive" role="alert">
              <p className="flex gap-2"><FaInfoCircle className='mt-1' />Silahkan memilih Tahun Ajaran atau tambah Tahun Ajaran untuk dikelola.</p>
            </div>
          }
          

        </section>

      </form>

      <DialogComponent 
        id="toolbarDialog" 
        ref={(dialog) => (toolbarDialog = dialog)}
        isModal={true}
        showCloseIcon={true} 
        animationSettings={animationSettings} 
        width="240px" 
        target={'body'} 
        header={status.toolbarClick + " Tahun Ajaran"}
        visible={status.hideDialog} 
        buttons={buttons} 
        // buttons={!selectedTahunAjaran.publish ? buttons : false} 
        open={dialogOpen} 
        close={dialogClose}
      >
        {status.toolbarClick == "Tidak dapat dihapus" ?
          <p>
            <strong>Tahun Ajaran {selectedTahunAjaran.tahun_ajaran} {status.toolbarClick}</strong> karena sudah <strong>Publish</strong>.
          </p>
        : 
          <p>
            Apakah Anda yakin ingin {status.toolbarClick} <strong>Tahun Ajaran {selectedTahunAjaran.tahun_ajaran}</strong>?
          </p>
        }
      </DialogComponent>
     
    </article>

    
  </>
  )
};
export default SetupPMB;

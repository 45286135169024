import { useState, useEffect } from 'react';
import { useImmer } from 'use-immer';
import Modal from 'react-modal';
import { Header } from '../../components';
import { InfiniteScroll, PdfExport, ExcelExport, Group, Freeze, GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, Edit, Sort, Search, Filter, Toolbar, ToolbarItems, CommandColumn, columnSelectionComplete } from '@syncfusion/ej2-react-grids';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { UploaderComponent, NumericTextBox } from '@syncfusion/ej2-react-inputs';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { MdVerified } from 'react-icons/md';
import { AiOutlineSave, AiOutlineFileJpg, AiOutlineFileImage } from 'react-icons/ai';
import { CgSpinner } from 'react-icons/cg';
import { L10n } from '@syncfusion/ej2-base';
import { useStateContext } from '../../contexts/ContextProviderAdminPMB';
import { dropdownData } from '../../data/initData';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import TextInput from '../../components/TextInput';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const SUBMIT_URL = '/api/pmb/payment-register/';

const KonfirmasiPembayaran = () => {
  const token = JSON.parse(localStorage.getItem('TOKEN'));
  const { downloadExcelPembayaran, successMsg, paymentUpload, allPaymentRegister, getAllPaymentRegister, isLoading, setIsLoading, errMsg, setErrMsg, setSuccessMsg } = useStateContext();
  const [isOpenModalMurid, setIsOpenModalMurid] = useState(false);
  const [isOpenModalStatus, setIsOpenModalStatus] = useState(false);
  const [murid, setMurid] = useState([{}]);
  const [selectedMurid, setSelectedMurid] = useState({});
  const [status, setStatus] = useImmer(
    {
      id: null,
      bukti: "",
      tgl_bayar: "",
      jenis_bayar: "",
      total: null,
      status_bukti: "",
      jumlah_anak: null,
      is_published: null,
      publish: null,
      user_id: null
    }
  );
  const [previewImageBukti, setPreviewImageBukti] = useState("")

  const customStyles = {
    content: {
      width: '85%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      cursor: 'auto',
      padding: '48px'
    },
    overlay: {
      backgroundColor: 'rgba(0,0,0,.5)',
      cursor: 'pointer'
    }
  };

  useEffect(() => {
    getAllPaymentRegister();
    console.log("allPaymentRegister === ", allPaymentRegister);
  },[]);

  let grid;
  let gridFees
  // const renderingMode = 'Vertical';
  const editSettings = { allowEditing: false, allowDeleting: false };
  const toolbarOptions = ['Search',
    { text: 'Unduh Excel', prefixIcon: 'e-export-excel', id: 'excel' }
  ];
  const commands = [
    {
      buttonOption: {iconCss: ' e-icons e-people', cssClass: 'e-outline', id: 'murid'},
      title: "Lihat Murid"
    },
    {
      buttonOption: {iconCss: ' e-icons e-changes-track', cssClass: 'e-outline', id: 'status-pembayaran'},
      title: "Status Pembayaran"
    },
  ];
  const commandClick = (args) =>  {
    const murid = args.rowData.students;
    const status = args.rowData.status_pembayaran;

    if (args.commandColumn.buttonOption.id == "murid") {
      console.log("args.rowData === ", args.rowData )
      setMurid(murid);
      setIsOpenModalMurid(true);
    }
    if (args.commandColumn.buttonOption.id == "status-pembayaran") {
      console.log("args.rowData === ", args.rowData )
      setStatus(status)
      setIsOpenModalStatus(true);
      setPreviewImageBukti("")
    }
  }
  const onToolbarClick = (args) => {
    console.log("args.item.id === ", args.item.id)
    if (grid && args.item.id === 'excel') {
      downloadExcelPembayaran()
      // grid.showSpinner();
      // const excelExportProperties = {
      //   // dataSource: selectedMurid,
      //   includeHiddenColumn: true
      // };
      // console.log("EXPORT DATA === ", excelExportProperties)
      // grid.excelExport(excelExportProperties);
    }
    else if(grid && args.item.id == "grid_pdfexport"){
      grid.showSpinner();
      grid.pdfExport();
    }
    else if(grid && args.item.id == "clear-filter"){
      grid.clearFiltering();
    }
  }
  // EXPORT COMPLETED
  const pdfExportComplete = () => {
    if (grid) {
      grid.hideSpinner();
    }
  };
  const excelExportComplete = () => {
    if (grid) {
      grid.hideSpinner();
    }
  };

  

  // ON ROW SELECTED
  const onRowSelected = (args) => {
    console.log('onRowSelected === ', args.data);
    setSelectedMurid(args.data);
  } 

  function closeModalMurid() {
    setIsOpenModalMurid(false);
  }

  function closeModalStatus() {
    setIsOpenModalStatus(false);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  Modal.setAppElement('#root');

  // ========
  // UPLOADER
  // ========
  let uploadObj;
  let asyncSettings;
  let dropContainerRef;
  let dropContainerEle;
  dropContainerEle = null;
  dropContainerRef = element => {
      dropContainerEle = element;
  };
  asyncSettings = {
      saveUrl: BASE_URL+`/api/pmb/payment-register/${status.user_id}`,
      removeUrl: 'https://ej2.syncfusion.com/services/api/uploadbox/Remove'
  };

  let minFileSize = 1000;
  let maxFileSize = 1000000;

  const onCreated = () => {
  }
  const onSelected = (args) => {
    console.log("onSelected arg === ", args)      
    const rawFile = args.filesData[0].rawFile;
    console.log("RAW FILE === ", rawFile)
    const previewImgUrl = URL.createObjectURL(args.filesData[0].rawFile);
    setPreviewImageBukti(previewImgUrl)
    console.log("FILE URL === ", previewImgUrl)
    setStatus((draft) => {
      draft.bukti = rawFile;
    });
    // setStatus((draft) => {
    //   draft.bukti = previewImgUrl;
    // });
  }
  function onRemoveFile(args) {
      args.postRawFile = false;
  }
  function onFileUpload(args) {
    console.log("UPLOADING..")
    console.log("onFileUpload === ", uploadObj)

    // const sizeInBytes = args.fileData.size;
    // alert("File size is: " + uploadObj.bytesToSize(sizeInBytes));

    args.customFormData = [
      {id: status.id},
      {bukti: status.bukti},
      {tgl_bayar: status.tgl_bayar},
      {jenis_bayar: status.jenis_bayar},
      {total: status.total},
      {status_bukti: status.status_bukti},
      {jumlah_anak: status.jumlah_anak},
      {publish: status.is_published},
      {user_id: status.user_id}
    ];
    (args.currentRequest).setRequestHeader('Authorization', `Bearer ${token}`)
  }
  function onSuccess(args) {
    // getDocumentsData();
    console.log("SUCCESS")
    getAllPaymentRegister();
    console.log("AFTER UPLOAD: allPaymentRegister === ", allPaymentRegister);
    setSuccessMsg("Pembayaran berhasil diupdate.")
    // const newBukti = allPaymentRegister.map((item, index) => {
    //   if(item.id == status.user_id){
    //     return item.status_pembayaran.bukti
    //   }
    // })

    // setStatus((draft) => {
    //   const data = draft.find((data) => data.id === status.user_id);
    //   status.bukti = data.status_pembayaran.bukti;
    // });
  }

  const uploadAll = () => {
    paymentUpload(status.user_id, status)
    // uploadObj.upload(uploadObj.getFilesData());
    // uploadObj.retry()

    // if(status.bukti){
    //   alert("RETRY")
    //   uploadObj.retry()
    // }else{
    //   alert("UPLOAD")
    //   uploadObj.upload(uploadObj.getFilesData());
    // }
  }

  const onFailed = (args) => {
    const res = JSON.parse(args.e.target.response)
    console.log("onFailed === ", res.errors.tgl_bayar[0])
    Object.entries(res.errors).map(([, fieldErrors]) => 
      fieldErrors.map((fieldError, index) => 
        setErrMsg(fieldError)
      )
    )
  }

  const handleSubmit = () => { 
    console.log("STATUS: === ", status)
    uploadAll()
    // if(!status.total || 
    //     !status.jenis_bayar || 
    //     !status.tgl_bayar || 
    //     !status.status_bukti
    //   )
    // {
    //   setErrMsg("Semua input harus diisi.")
    // }else{
    //   uploadAll()
    // }
  }


  useEffect(() => {
    L10n.load({
      "id-BAHASA": {
        "uploader": {
          "Browse": "Cari Berkas",
          "Clear": "Bersihkan",
          "Upload": "Unggah",
          "cancel": "Batal",
          "delete": "Hapus Berkas",
          "dropFilesHint": "/ taruh Berkas disini",
          "inProgress": "Mengunduh",
          "invalidFileType": "Tipe berkas tidak diperbolehkan",
          "invalidMaxFileSize": `Ukuran berkas melebihi ${maxFileSize*0.000001} MB`,
          "invalidMinFileSize": `Ukuran file terlalu kecil! Harap unggah file dengan ukuran minimal ${maxFileSize*0.000001} KB`,
          "readyToUploadMessage": "Siap mengunggah",
          "remove": "Hapus",
          "removedFailedMessage": "Berkas tidak dapat dihapus",
          "removedSuccessMessage": "Berkas berhasil dihapus",
          "uploadFailedMessage": "Gagal mengunggah berkas",
          "uploadSuccessMessage": "Berkas berhasil diunggah",
        }
      }
    });
  },[]); 


  const updateTextInput = (value, fieldName, string) => {
    console.log("updateTextInput === ", string);
    console.log("fieldName === ", fieldName);
    setStatus(draft => {
      draft[fieldName] = value
    })
  }

  const updateDropDownCal = e => {
    const fieldName = e.element.ej2_instances[0].htmlattributes.name
    console.log("fieldName ===> ", fieldName)
    setStatus(draft => {
      draft[fieldName] = e.element.value
    })
  }

  const handleCheckbox = e => {
    console.log(e.checked)
    setStatus(draft => {
      draft["is_published"] = e.checked
    })
    setStatus((draft) => {
      draft["publish"] = e.checked
    });
  }

  // TOAST MESSAGE
  useEffect(() => {
    if(successMsg){
      successShow();
    }
  }, [successMsg])

  useEffect(() => {
    if(errMsg){
      dangerShow();
    }
  }, [errMsg])

  let toastObj;

  function toastClick() {
      console.log('Toast click event triggered');
  }
  function toastClose() {
      toastObj.hide('All');
  }
  
  function successShow() {
      toastObj = ToastUtility.show({
        title: 'Sukses',
        content: successMsg,
        cssClass: 'e-toast-success',
        timeOut: 3000,
        position: { X: 'Center', Y: 'Top' },
        showCloseButton: true,
        click: toastClick.bind(this),
        showProgressBar: true 
      });
      // CLEAR MESSAGE
      setTimeout(function () {
        setSuccessMsg("");
      }.bind(this), 7000);
  }
  function dangerShow() {
      toastObj = ToastUtility.show({
        title: 'Terjadi Kesalahan',
        content: errMsg,
        cssClass: 'e-toast-danger',
        timeOut: 7000,
        position: { X: 'Center', Y: 'Top' },
        showCloseButton: true,
        click: toastClick.bind(this),
        showProgressBar: true 
      });
      // CLEAR MESSAGE
      setTimeout(function () {
        setErrMsg("");
      }.bind(this), 7000);
  }

  const valueAccess = (field, data, column) => {
    console.log("valueAccess === ", data)
    if(data.status_pembayaran.total){
      const value = data.status_pembayaran.total;
      return "Rp " + new Intl.NumberFormat('de-DE', {minimumFractionDigits: 0}).format(value);
    }
    // const value = data.status_pembayaran.total;
    // return <NumericTextBox value={value}></NumericTextBox>
    // return "Rp " + new Intl.NumberFormat('de-DE', {minimumFractionDigits: 0}).format(value);
  };

  const valueAccessStatus = (field, data, column) => {
    console.log("valueAccessStatus === ", data)
    const value = data.status_pembayaran.status_bukti;
    if(value == "Lunas"){
      return `<span class='text-green-600 font-semibold'>LUNAS</span>`
    }else{
      return `<span class='text-merah font-semibold'>BELUM LUNAS</span>`
    }
  };

  const valueAccessPublish = (field, data, column) => {
    console.log("valueAccessPublish === ", data)
    const value = data.status_pembayaran.is_published;
    if(value){
      return '<span class="e-btn-icon e-check e-icons e-large text-green-600"></span>'
    }
  };

  const integerParams = {
    params: {
      decimals: 0,
      // format: 'C', 
      // currency:'DE',
      min: 0,
      validateDecimalOnType: true,
    },
  };

  const customAttributes = { class: 'customcss' };

  const headerCellInfo = (args) => {
    args.node.classList.add('sort-icon');
  }

  return(
    <>
      <Header category="Admin PMB" title="Konfirmasi Pembayaran" />

      <article>
        
        <GridComponent 
          id="grid" 
          ref={g => grid = g}
          dataSource={allPaymentRegister} 
          toolbar={toolbarOptions} 
          editSettings={editSettings} 
          enableStickyHeader={true}
          rowHeight="40" 
          commandClick={commandClick} 
          rowSelected={onRowSelected} 
          // enableAdaptiveUI={true} 
          // rowRenderingMode={renderingMode} 
          allowSorting={true}
          allowFiltering={false}
          // allowExcelExport={true}
          // allowPdfExport={true}
          // allowGrouping={true}
          allowMultiSorting={true}
          // allowPaging={true}
          allowTextWrap={false}
          // clipMode='Clip'
          toolbarClick={onToolbarClick} 
          pageSettings={{ pageSize: 10 }}
          gridLines={'Both'}
          // frozenColumns={2}
          // enableVirtualization={true}
          enableInfiniteScrolling={true} 
          // pdfExportComplete={pdfExportComplete} 
          // excelExportComplete={excelExportComplete}
          autoFit={true}
          headerCellInfo={headerCellInfo}
        >
          <ColumnsDirective>
            <ColumnDirective field='id' headerText='ID' width='40' isPrimaryKey={true} visible={false}/>
            <ColumnDirective field='nama_lengkap' headerText='Nama Lengkap Anak' width='140'/>
            <ColumnDirective field='email' headerText='Email' width='140'/>
            <ColumnDirective field='nomor_ponsel' headerText='No. Ponsel' width='110'/>
            <ColumnDirective field='jumlah_daftar_anak' headerText='Anak' width='65' textAlign="Center" />
            <ColumnDirective field='status_pembayaran.total' headerText='Total' width='100' editType='numericedit' edit={integerParams}  valueAccessor={valueAccess}  textAlign="Right" />
            <ColumnDirective field='status_pembayaran.jenis_bayar' headerText='Jenis Pembayaran' width='120' textAlign="Center"/>
            <ColumnDirective field='status_pembayaran.tgl_bayar' headerText='Tgl. Bayar' width='130'/>
            <ColumnDirective freeze='Right' field='status_pembayaran.status_bukti' headerText='Status' width='110' textAlign="Center" valueAccessor={valueAccessStatus} disableHtmlEncode={false} />
            <ColumnDirective freeze='Right' field='status_pembayaran.is_published' headerText='Publish' width='75' textAlign="Center" valueAccessor={valueAccessPublish} disableHtmlEncode={false} />
            {/* <ColumnDirective freeze='Right' field='status_pembayaran.is_published' headerText='Publish' width='75' displayAsCheckBox={true} editType='booleanedit' textAlign="Center" /> */}
            <ColumnDirective freeze='Right' allowFiltering={false} field='' headerText='Atur' width='120' textAlign="Center" commands={commands} customAttributes={customAttributes} />
          </ColumnsDirective>
          <Inject services={[InfiniteScroll, Edit, Search, Sort, Toolbar, Filter, CommandColumn, ExcelExport, PdfExport, Freeze]}/>
        </GridComponent>

        {/* MODAL MURID DETAIL */}
        <Modal
          isOpen={isOpenModalMurid}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModalMurid}
          style={customStyles}
          contentLabel="Modal Murid"
        >
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Data Murid</h2> */}
          <h2 className='mb-3 text-merah'>{selectedMurid.nama_lengkap}</h2>
          {/* <button onClick={closeModalMurid}>close</button> */}

          <GridComponent 
            dataSource={murid} 
            editSettings={editSettings} 
            rowHeight="40" 
            enableAdaptiveUI={true} 
            // rowRenderingMode={renderingMode}
            gridLines={'Both'}
          >
            <ColumnsDirective>
              <ColumnDirective field='id' headerText='ID' width='30' isPrimaryKey={true} visible={false} />
              <ColumnDirective field='nama_lengkap_anak' headerText='Name Lengkap' width='190'/>
              <ColumnDirective field='nomor_akta_lahir_anak' headerText='No. Akta Lahir' width='100'/>
              <ColumnDirective field='nama_ayah' headerText='Nama Ayah' width='190'/>
              {/* <ColumnDirective field='alamat_ayah' headerText='Alamat Ayah' width='120'/> */}
              <ColumnDirective field='nama_ibu' headerText='Nama Ibu' width='190'/>
              {/* <ColumnDirective field='alamat_ibu' headerText='Alamat Ibu' width='120'/> */}
              <ColumnDirective field='nama_wali' headerText='Nama Wali' width='190'/>
              {/* <ColumnDirective field='alamat_wali' headerText='Alamat Wali' width='120'/> */}
            </ColumnsDirective>
            <Inject services={[Edit, CommandColumn]}/>
          </GridComponent>

        </Modal>

        <Modal
          isOpen={isOpenModalStatus}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModalStatus}
          style={customStyles}
          contentLabel="Modal Status"
        >
          <h2 className='mb-3 text-merah'>{selectedMurid.nama_lengkap}</h2>

          <form className='grid mt-3 xs:grid-cols-1 md:grid-cols-2 gap-7' >

            <section>
              <TextInput 
                label="Total Bayar"
                type="currency"
                name="total"
                onChange={updateTextInput}
                value={status.total}
                required={true}
                min="1"
              />

              <div className={`flex flex-wrap`}>
                <label htmlFor="nominal_wakaf" className="mt-4 mb-1 form-label">
                  Jenis Bayar <span className="ml-1 text-merah">*</span>
                </label>
                <DropDownListComponent 
                  placeholder=""
                  name="jenis_bayar" 
                  dataSource={dropdownData.jenisBayar} 
                  fields={ { value: 'text', text: 'text' }} 
                  value={status.jenis_bayar} 
                  change={updateDropDownCal} 
                  popupHeight="auto" 
                />
              </div>

              <div className="flex flex-wrap">
                <label htmlFor="tanggal_lahir" className="flex w-full mt-4 mb-1 form-label">
                  Tanggal Bayar<span className="ml-1 text-merah">*</span>
                </label>
                <DatePickerComponent 
                  name="tgl_bayar"
                  value={status.tgl_bayar} 
                  change={updateDropDownCal}  
                  format="dd MMMM yyyy"
                />
              </div>

              <div className={`flex flex-wrap`}>
                <label htmlFor="nominal_wakaf" className="mt-4 mb-1 form-label">
                  Status Bukti <span className="ml-1 text-merah">*</span>
                </label>
                <DropDownListComponent 
                  placeholder=""
                  name="status_bukti" 
                  dataSource={dropdownData.statusBukti} 
                  fields={ { value: 'text', text: 'text' }} 
                  value={status.status_bukti} 
                  change={updateDropDownCal} 
                  popupHeight="auto" 
                />
              </div>
            </section>

            <section>
              <label htmlFor="akte_kelahiran" className="block mt-4 mb-1">
                Bukti Pembayaran {!status.bukti ? <span className="ml-1 text-merah">*</span> : <span><MdVerified className='inline-block text-md text-green-600 ml-0.5 mb-1' /> <strong className='text-green-600 text'>Sudah Diunggah</strong></span>}
              </label>
              <div className="flex items-center justify-center w-full p-3 mb-3 text-center rounded-lg h-[240px] bg-soft overflow-y-auto">
                {previewImageBukti ?
                <img src={previewImageBukti} className="w-full m-auto" />
                : status.bukti ?
                <img src={status.bukti} className="w-full m-auto" />
                :
                <span><AiOutlineFileImage className='inline-block text-md text-merah text-5xl ml-0.5 mb-1' /></span>
                }
              </div>
              {/* {!status.is_published && */}
              <>
                <UploaderComponent 
                  id='bukti' 
                  type='file' 
                  ref={upload => { uploadObj = upload; }}
                  asyncSettings={asyncSettings} 
                  removing={onRemoveFile.bind(this)}
                  uploading={onFileUpload.bind(this)} 
                  success={onSuccess.bind(this)} 
                  locale='id-BAHASA'
                  allowedExtensions='.jpg,.png,.jpeg'
                  minFileSize={minFileSize}
                  maxFileSize={maxFileSize} 
                  multiple={false}
                  autoUpload={false}
                  buttons={{ browse: !status.bukti ? "Unggah Berkas" : "Ganti Berkas" }}
                  selected={onSelected} 
                  created={onCreated}
                  showFileList={true}
                  failure={onFailed}
                >
                  {/* <FilesDirective>
                    <UploadedFilesDirective name={name_foto} size={25000} type={foto_extension}></UploadedFilesDirective>
                  </FilesDirective> */}
                </UploaderComponent>
                <small className='text-gray-400 '><i>Jenis berkas: .jpg, .png, max: 1 MB</i></small>
                </>
              {/* } */}

              {/* <input
                type="file"
                onChange={e => {
                  const imgUrl = URL.createObjectURL(e.target.files[0]);
                  console.log("e.target === ", e);
                  console.log(imgUrl);
                  setImg(imgUrl);
                }}
              />

              {img && (
                <img src={img} alt="preview" width="200" hieght="200" />
              )} */}

            </section>

          </form>

          <section className='flex mt-3'>
            
            
            <button className="w-auto btn-merah" onClick={handleSubmit}>
              {isLoading ? <CgSpinner className="mr-2 text-xl animate-spin" /> : <AiOutlineSave className='mr-2 text-2xl' /> }
              Update Pembayaran
            </button>

            <div className='ml-3 mt-9'>
              <CheckBoxComponent change={handleCheckbox.bind(this)} label="Publish" data-name="publish" value={status.is_published} checked={status.is_published} cssClass="e-success" />
            </div>
          </section>

        </Modal>

      </article>
    </>
  )
};
export default KonfirmasiPembayaran;

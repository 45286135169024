import React, { useEffect } from 'react';
import { FaRegUserCircle } from 'react-icons/fa';
import { MdPayment } from 'react-icons/md';
import { BiEdit } from 'react-icons/bi';
import { GoChecklist } from 'react-icons/go';
import { BsBarChartSteps } from 'react-icons/bs';
import { notifData } from '../data/initData';
import { useStateContext } from '../contexts/ContextProvider';
import { format, formatDistanceToNow } from 'date-fns' 

const Notification = ({ notificationData, unreadTotal, newNotif }) => {
  const { currentColor, getNotifications, notifications } = useStateContext();

  const date = new Date('2023-02-08T18:05:25.000000Z')

  useEffect(() => {
    console.log(format(new Date(date), 'yyyy-MM-dd'))
    setTimeout(() => getNotifications(1), 500);
  }, []);

  

  return (
    <div className="nav-item absolute right-6 top-16 bg-white dark:bg-[#42464D] p-7 rounded-lg w-320 drop-shadow-2xl">
      <div className="flex items-center justify-between">
        <div className="flex gap-3">
          <p className="text-lg font-semibold text-merah dark:text-gray-200">Notifikasi</p>
          {unreadTotal ? <button type="button" className="w-6 h-6 text-xs rounded-full text-putih bg-merah">{unreadTotal}</button> : ''}
        </div>
      </div>
      <div>

        {notificationData?.map((item, index) => (
          <div key={index} className="relative flex items-center gap-3 p-3 pl-0 leading-8 border-b-1 border-color">
            {/* <img className="w-12 h-12 rounded-full" src={item.image} alt={item.message} /> */}
            <div>
              <small className='font-bold text-[10px]'>
                {
                  item.type == "App\\Notifications\\FormSubmit" ?
                  <div className='flex items-center gap-1'><BiEdit/> <span>Pengisian Formulir</span></div>
                  : item.type == "App\\Notifications\\StudentTestResult" ?
                  <div className='flex items-center gap-1'><FaRegUserCircle/> <span>Hasil Tes</span></div>
                  : item.type == "App\\Notifications\\PaymentRegisterUser" ?
                  <div className='flex items-center gap-1'><MdPayment/> <span>Pembayaran Registrasi</span></div>
                  : item.type == "App\\Notifications\\InvoicePaymentRegisterUser" ?
                  <div className='flex items-center gap-1'><MdPayment/> <span>Tagihan Pembayaran Registrasi</span></div>
                  : item.type == "App\\Notifications\\DaftarUlang" ?
                  <div className='flex items-center gap-1'><GoChecklist/> <span>Daftar Ulang</span></div>
                  : item.type == "App\\Notifications\\ReminderTahapan" ?
                  <div className='flex items-center gap-1'><BsBarChartSteps/> <span>Jatuh Tempo </span></div>
                  : 
                  ""
                }
              </small>
              <p className={`text-xs ${item.read_at === null && 'text-merah'} dark:text-gray-200`}>{item.data.title}</p>
              {/* <p className="text-sm text-gray-500 dark:text-gray-400"> {item.data.title} </p> */}
            </div>
            <small className='absolute top-3 right-0 flex items-center gap-1 text-[9px] text-gray-400'>
              <span>{formatDistanceToNow(new Date(date), {addSuffix: true})}</span>
            </small>
          </div>
        ))}
        {/* <div className="mt-5">
          <Button color="white" bgColor={currentColor} text="Lihat semua notifikasi" borderRadius="10px" width="full" />
        </div> */}
      </div>  
    </div>
  );
};

export default Notification;
